























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheLoginTabs',
})
export default class TheLoginTabs extends Vue {
  @Prop({ type: Array, required: true }) readonly tabs!: Array<{value: string, label: string}>
  @Prop({ type: String, required: true }) readonly value!: string
}
